import { render, staticRenderFns } from "./NotAuthorized.vue?vue&type=template&id=0a8098d8&"
import script from "./NotAuthorized.vue?vue&type=script&lang=js&"
export * from "./NotAuthorized.vue?vue&type=script&lang=js&"
import style0 from "./NotAuthorized.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jasontaylor/Sites/Cancom_Secure_Portal_2021/cancom-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a8098d8')) {
      api.createRecord('0a8098d8', component.options)
    } else {
      api.reload('0a8098d8', component.options)
    }
    module.hot.accept("./NotAuthorized.vue?vue&type=template&id=0a8098d8&", function () {
      api.rerender('0a8098d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/miscellaneous/NotAuthorized.vue"
export default component.exports